import React from "react";

export default function Bess() {
  return (
    <div className="footer-bg ">
      <div className="  w-11/12 2xl:w-[1216px] mx-auto md:h-[437px] pb-[41px] pt-[41px]  md:pt-[373px] ">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 sm:col-span-6">
            <div className="text-center sm:text-start">
              <h1 className="f-f-r text-lg text-grey">
                Copyright © 2022 EVOLTA Electricity
              </h1>
            </div>
          </div>

          <div className="col-span-12 sm:col-span-6">
            <div className="text-center sm:text-end mt-5 sm:mt-0">
              <ul className="inline-flex items-center space-x-6">
                <li>
       
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M9 1.53003C4.875 1.53003 1.5 4.89753 1.5 9.04503C1.5 12.795 4.245 15.9075 7.83 16.47V11.22H5.925V9.04503H7.83V7.38753C7.83 5.50503 8.9475 4.47003 10.665 4.47003C11.4825 4.47003 12.3375 4.61253 12.3375 4.61253V6.46503H11.3925C10.4625 6.46503 10.17 7.04253 10.17 7.63503V9.04503H12.255L11.9175 11.22H10.17V16.47C11.9373 16.1909 13.5467 15.2892 14.7074 13.9276C15.8682 12.566 16.504 10.8343 16.5 9.04503C16.5 4.89753 13.125 1.53003 9 1.53003Z"
                      fill="#E5E5DF"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M16.845 4.5C16.2675 4.7625 15.645 4.935 15 5.0175C15.66 4.62 16.17 3.99 16.41 3.2325C15.7875 3.6075 15.0975 3.87 14.37 4.02C13.7775 3.375 12.945 3 12 3C10.2375 3 8.7975 4.44 8.7975 6.2175C8.7975 6.4725 8.8275 6.72 8.88 6.9525C6.21 6.8175 3.8325 5.535 2.25 3.5925C1.9725 4.065 1.815 4.62 1.815 5.205C1.815 6.3225 2.3775 7.3125 3.2475 7.875C2.715 7.875 2.22 7.725 1.785 7.5C1.785 7.5 1.785 7.5 1.785 7.5225C1.785 9.0825 2.895 10.3875 4.365 10.68C4.095 10.755 3.81 10.7925 3.5175 10.7925C3.315 10.7925 3.1125 10.77 2.9175 10.7325C3.3225 12 4.5 12.945 5.9175 12.9675C4.8225 13.8375 3.435 14.3475 1.92 14.3475C1.665 14.3475 1.41 14.3325 1.155 14.3025C2.58 15.2175 4.275 15.75 6.09 15.75C12 15.75 15.2475 10.845 15.2475 6.5925C15.2475 6.45 15.2475 6.315 15.24 6.1725C15.87 5.7225 16.41 5.1525 16.845 4.5Z"
                      fill="#E5E5DF"
                    />
                  </svg>
                  
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 4C10.4177 4 8.87103 4.46919 7.55544 5.34824C6.23985 6.22729 5.21447 7.47671 4.60897 8.93852C4.00347 10.4003 3.84504 12.0089 4.15372 13.5607C4.4624 15.1126 5.22433 16.538 6.34315 17.6568C7.46197 18.7757 8.88743 19.5376 10.4393 19.8463C11.9911 20.1549 13.5997 19.9965 15.0615 19.391C16.5233 18.7855 17.7727 17.7601 18.6517 16.4445C19.5308 15.129 20 13.5822 20 12C20 9.87826 19.1571 7.84342 17.6569 6.34313C16.1566 4.84284 14.1217 4 12 4ZM12 5.39196C13.4584 5.39118 14.8753 5.87744 16.0259 6.7736C15.1491 7.86453 14.0045 8.7096 12.7038 9.22637C12.0183 7.96414 11.2418 6.75352 10.3803 5.60413C10.9091 5.46524 11.4533 5.39395 12 5.39196ZM9.01423 6.12677C9.88739 7.26282 10.676 8.46137 11.3739 9.71279C9.48847 10.2229 7.54167 10.4701 5.58862 10.4476C5.81607 9.52373 6.23974 8.65965 6.83083 7.91408C7.42193 7.16851 8.16659 6.55894 9.01423 6.12677ZM5.39198 12.0155C5.39198 11.9586 5.39198 11.9017 5.39198 11.8447C7.63345 11.8843 9.86809 11.586 12.0207 10.9599C12.1759 11.2755 12.3208 11.5964 12.4657 11.9172C10.1803 12.6632 8.22737 14.1842 6.94438 16.2173C5.94701 15.0379 5.39734 13.5446 5.39198 12V12.0155ZM11.9948 18.6184C10.5255 18.617 9.09891 18.1233 7.94308 17.216C9.07445 15.2969 10.8778 13.866 13.0039 13.2005C13.624 14.808 14.08 16.4741 14.3648 18.1733C13.6098 18.4613 12.808 18.607 12 18.6028L11.9948 18.6184ZM15.6636 17.5058C15.3702 15.9403 14.9426 14.4029 14.3855 12.9107C15.7635 12.7336 17.161 12.7773 18.5252 13.0401C18.3845 13.9424 18.0579 14.8057 17.5662 15.5753C17.0745 16.3449 16.4284 17.004 15.6688 17.511L15.6636 17.5058ZM13.8525 11.5912C13.6869 11.2186 13.5213 10.8409 13.3351 10.4735C14.7714 9.8832 16.0403 8.94842 17.0297 7.75161C17.9576 8.84483 18.5027 10.2113 18.5821 11.6429C17.0222 11.3557 15.4251 11.333 13.8577 11.5757L13.8525 11.5912Z"
                      fill="#E5E5DF"
                    />
                  </svg>

                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M9 1.5C8.01509 1.5 7.03982 1.69399 6.12987 2.0709C5.21993 2.44781 4.39314 3.00026 3.6967 3.6967C2.29018 5.10322 1.5 7.01088 1.5 9C1.5 12.315 3.6525 15.1275 6.63 16.125C7.005 16.185 7.125 15.9525 7.125 15.75C7.125 15.5775 7.125 15.105 7.125 14.4825C5.0475 14.9325 4.605 13.4775 4.605 13.4775C4.26 12.6075 3.7725 12.375 3.7725 12.375C3.09 11.91 3.825 11.925 3.825 11.925C4.575 11.9775 4.9725 12.6975 4.9725 12.6975C5.625 13.8375 6.7275 13.5 7.155 13.32C7.2225 12.8325 7.4175 12.5025 7.6275 12.315C5.9625 12.1275 4.215 11.4825 4.215 8.625C4.215 7.7925 4.5 7.125 4.9875 6.5925C4.9125 6.405 4.65 5.625 5.0625 4.6125C5.0625 4.6125 5.6925 4.41 7.125 5.3775C7.7175 5.2125 8.3625 5.13 9 5.13C9.6375 5.13 10.2825 5.2125 10.875 5.3775C12.3075 4.41 12.9375 4.6125 12.9375 4.6125C13.35 5.625 13.0875 6.405 13.0125 6.5925C13.5 7.125 13.785 7.7925 13.785 8.625C13.785 11.49 12.03 12.12 10.3575 12.3075C10.6275 12.54 10.875 12.9975 10.875 13.695C10.875 14.7 10.875 15.51 10.875 15.75C10.875 15.9525 10.995 16.1925 11.3775 16.125C14.355 15.12 16.5 12.315 16.5 9C16.5 8.01509 16.306 7.03982 15.9291 6.12987C15.5522 5.21993 14.9997 4.39314 14.3033 3.6967C13.6069 3.00026 12.7801 2.44781 11.8701 2.0709C10.9602 1.69399 9.98491 1.5 9 1.5Z"
                      fill="#E5E5DF"
                    />
                  </svg>
             
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
