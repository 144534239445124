import React from "react";
import Bussinessplan from "../../Assets/elvotaimages/Bussinessplan.png";
import Powerd from "../../Assets/elvotaimages/Powerd.png";
import Arrowimg from "../../Assets/elvotaimages/Arrowimg.png";

export default function Bess() {
  return (
    <div className="  w-11/12 2xl:w-[1222px] mx-auto pt-[70px] lg:pt-[127px] pb-[100px] lg:pb-[148px] ">
      <div className="grid grid-cols-12 sm:gap-[42px] items-center ">
        <div className="col-span-12 lg:col-span-4">
          <div className="relative w-[300px] sm:w-[340px] mx-auto lg:mx-0 lg:w-full">
            <h1 className="f-f-b-ex text-[100px] text-black">20%</h1>

            <img
              src={Arrowimg}
              className="absolute right-[-20px] xl:right-[22px] top-[-55px] sm:top-[-38px]"
              alt=""
            />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-8">
          <h1 className="f-f-b-ex text-[34px] sm:text-6xl text-black leading-[48px] mt-10 sm:mt-0">
            Additional Revenue with our AI-powered energy optimizer
          </h1>
          <p className="f-f-r text-lg text-black leading-[23px] mt-3 sm:mt-5">
            Our precise forecasting and optimization techniques ensure that
            every charge and discharge decision maximizes profitability and
            efficiency, setting us apart in the energy storage market.
          </p>
        </div>
      </div>

      <div className="mt-[110px] sm:mt-[197px] ">
        <h1 className="f-f-b-ex text-5xl sm:text-6xl text-black leading-[48px]">
          EVOLTA News & Insights
        </h1>
        <p className="f-f-r text-lg text-black leading-[23px] mt-3 sm:mt-[10px]">
          Our precise forecasting and optimization techniques ensure that every
          charge and discharge decision maximizes profitability and efficiency,
          setting us apart in the energy storage market.
        </p>
      </div>
      <div className="mt-10 xl:mt-0 xl:p-10">
        <div className="grid grid-cols-12 gap-5 lg:gap-10">
          <div className="col-span-12 lg:col-span-6">
            <div className=" relative text-black hover:text-white bg-class-for-test p-4 sm:p-0 h-auto lg:h-[492px] lg:hover:h-[512px] sm:pt-[21px] sm:pb-[47px]  sm:px-[25px]  rounded-[20px] ">
              <div className="bg-animated"></div>
              <div className="z-10 relative">
                <img src={Bussinessplan} className="w-full h-auto " alt="" />

                <button className=" w-[80px] h-[40px] rounded-[100px] bg-green f-f-m text-tiny  text-black-light mt-[15px]">
                  Evolta
                </button>

                <h1 className="f-f-b-ex text-[18px] sm:text-xl  mt-2 ">
                  BESS Business Plan Development
                </h1>
                <p className="f-f-r text-lg  mt-2 pb-8 sm:pb-0 ">
                  Accurate financial planning with state-of-the-art and
                  data-driven algorithms to ensure successful BESS project
                  development.
                </p>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <div className=" text-black hover:text-white relative bg-class-for-test p-4 sm:p-0 h-auto lg:h-[492px] lg:hover:h-[512px]   sm:pt-[21px] sm:pb-[47px]  sm:px-[25px]  rounded-[20px] ">
              <div className="bg-animated"></div>
              <div className="z-10 relative">
                <img src={Powerd} className="w-full h-auto" alt="" />

                <button className=" w-[80px] h-[40px] rounded-[100px] bg-green f-f-m text-tiny  text-black-light mt-[15px]">
                  Evolta
                </button>

                <h1 className="f-f-b-ex text-[18px] sm:text-xl  mt-2 ">
                  AI-Powered Optimisation Service
                </h1>
                <p className="f-f-r text-lg  mt-2 pb-8 sm:pb-8 ">
                  Advances forecasting and optimization to enhance BESS
                  operations, increasing your profits and extending your system
                  lifespan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
