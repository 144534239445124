import React from 'react'
import Home from './Components/Home/Index'
import Aboutus from './Components/Aboutus/index'
import Solution from './Components/Solution/index'
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import './App.css'
export default function App() {
  return (
    <div  >
     <Router>
         <Routes>
         <Route exact path="/" element={<Home/>} />
         <Route exact path="/aboutus" element={<Aboutus/>} />
         <Route exact path="/solution" element={<Solution/>} />
         </Routes>
         </Router>
    </div>
  )
}
