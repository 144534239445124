import React from "react";
import Contenslider from "./Contentsslider";

export default function Bess() {
  return (
    <div className="Hero-bg">
      <div className="  w-11/12 2xl:w-[1150px] mx-auto pt-[260px] md:pt-[375px] pb-[80px] md:pb-[130px]  ">
        <div className="powering-bg w-full lg:w-[837px] py-6  px-[35px] h-[229px]  ">
          <div className=" content  ">
            <Contenslider />
          </div>
        </div>
      </div>
    </div>
  );
}
