// import React from "react";
// import Slider from "react-slick";

// export default function SimpleSlider() {
//   var settings = {
//     dots: true,
//     infinite: true,
//     arrows: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     nextArrow: (
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="35"
//         height="35"
//         viewBox="0 0 35 35"
//         fill="none"
//       >
//         <path
//           d="M15.4915 11.38L21.9943 17.8828L15.4915 24.3857"
//           stroke="#E5E5DF"
//         />
//         <circle cx="17.6263" cy="17.3737" r="16.8737" stroke="#E5E5DF" />
//       </svg>
//     ),
//     prevArrow: (
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         width="36"
//         height="35"
//         viewBox="0 0 36 35"
//         fill="none"
//       >
//         <path
//           d="M20.0138 11.38L13.511 17.8828L20.0138 24.3857"
//           stroke="#E5E5DF"
//         />
//         <circle
//           cx="17.3737"
//           cy="17.3737"
//           r="16.8737"
//           transform="matrix(-1 0 0 1 35.2527 0)"
//           stroke="#E5E5DF"
//         />
//       </svg>
//     ),
//   };
//   return (
//     <Slider {...settings} className=" slider-setting ">
//       <div>
//         <h1 className="f-f-b-ex text-[28px] sm:text-5xl md:text-6xl text-white sm:leading-[48px]">
//           POWERING <span className="text-green">THE FUTURE OF ENERGY</span>{" "}
//           STORAGE TRADING
//         </h1>
//         <p className="f-f-r text-lg sm:text-xl text-white mt-3 pb-[16px] ">
//           When fintech meets cleantech
//         </p>
//       </div>

//       <div>
//         <h1 className="f-f-b-ex text-[28px] sm:text-5xl md:text-6xl text-white sm:leading-[48px]">
//           POWERING <span className="text-green">THE FUTURE OF ENERGY</span>{" "}
//           STORAGE TRADING
//         </h1>
//         <p className="f-f-r text-lg sm:text-xl text-white mt-3 pb-[16px] ">
//           When fintech meets cleantech
//         </p>
//       </div>
//     </Slider>
//   );
// }


import React from "react";
import Slider from "react-slick";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick}  className="slick-next" >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
      >
        <path
          d="M15.4915 11.38L21.9943 17.8828L15.4915 24.3857"
          stroke="#E5E5DF"
        />
        <circle cx="17.6263" cy="17.3737" r="16.8737" stroke="#E5E5DF" />
      </svg>
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="slick-prev " >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="35"
        viewBox="0 0 36 35"
        fill="none"
      >
        <path
          d="M20.0138 11.38L13.511 17.8828L20.0138 24.3857"
          stroke="#E5E5DF"
        />
        <circle
          cx="17.3737"
          cy="17.3737"
          r="16.8737"
          transform="matrix(-1 0 0 1 35.2527 0)"
          stroke="#E5E5DF"
        />
      </svg>
    </div>
  );
};

export default function SimpleSlider() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    // autoplay: true,

    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings} className="slider-setting">
      <div>
        <h1 className="f-f-b-ex text-[28px] sm:text-5xl md:text-6xl text-white sm:leading-[48px]">
          POWERING <span className="text-green">THE FUTURE OF ENERGY</span>{" "}
          STORAGE TRADING
        </h1>
        <p className="f-f-r text-lg sm:text-xl text-white mt-3 pb-[16px]">
          When fintech meets cleantech
        </p>
      </div>
      <div>
        <h1 className="f-f-b-ex text-[28px] sm:text-5xl md:text-6xl text-white sm:leading-[48px]">
          POWERING <span className="text-green">THE FUTURE OF ENERGY</span>{" "}
          STORAGE TRADING
        </h1>
        <p className="f-f-r text-lg sm:text-xl text-white mt-3 pb-[16px]">
          When fintech meets cleantech
        </p>
      </div>
    </Slider>
  );
}

