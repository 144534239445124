import React from "react";
import Bess from "./Bess";
import Additional from "./Additional-Revenue";
import Expanding from "./Expanding";
import Footer from "./Footer";
import Hero from "./Hero";
import Navbar from "./Navbar";

export default function Index() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Bess />
      <div className="additional-bg pb-[64px] sm:pb-0 ">
        <Additional />
      </div>
      <div className=" relative ">
        <Expanding />
        <Footer />
      </div>
    </div>
  );
}
