import React from "react";
import Mapimg from "../../Assets/elvotaimages/mapimage.png";

export default function Bess() {
  return (
    <>
      <div className="  w-11/12 2xl:w-[1222px] mx-auto   ">
        <h1 className="f-f-b-ex text-5xl sm:text-6xl text-black leading-[48px]">
          Expanding our Global Footprint
        </h1>
        <p className="f-f-r text-lg text-black leading-[23px] mt-3 sm:mt-[10px]">
          Our precise forecasting and optimization techniques ensure that every
          charge and discharge decision maximizes profitability and efficiency,
          setting us apart in the energy storage market.
        </p>

        <img src={Mapimg} className="w-full h-auto" alt="" />
      </div>
      <div className="  md:absolute md:w-full md:bottom-[86px] " id="contactus">
        <div className=" w-full lg:w-[752px] mx-auto">
          <h1 className="f-f-b-ex text-5xl sm:text-6xl text-black leading-[48px] text-center">
            Contact Us
          </h1>
          <p className="f-f-r text-lg text-black leading-[23px] mt-3 sm:mt-[10px] text-center">
            Get in touch to learn more about how we can build a clean energy
            future. Together.
          </p>
          <div className="contact-us mt-5 w-full h-auto sm:h-[600px] p-5 sm:p-8 md:p-0  md:py-[48px] md:px-10 bg-white rounded-[20px] border border-grey  ">
            <div className="grid grid-cols-12 gap-5 md:gap-[33px] items-center">
              <div className="col-span-12 md:col-span-6">
                <input
                  type="text"
                  className="w-full h-[52px] border border-grey rounded-lg  outline-none  f-f-r text-lg text-black pl-[22px]"
                  placeholder="First Name"
                />
              </div>

              <div className="col-span-12 md:col-span-6">
                <input
                  type="text"
                  className="w-full h-[52px] border border-grey rounded-lg  outline-none  f-f-r text-lg text-black pl-[22px]"
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div className="grid grid-cols-12 gap-5 md:gap-[33px] items-center mt-5">
              <div className="col-span-12 md:col-span-6">
                <input
                  type="text"
                  className="w-full h-[52px] border border-grey rounded-lg  outline-none  f-f-r text-lg text-black pl-[22px]"
                  placeholder="Company"
                />
              </div>

              <div className="col-span-12 md:col-span-6">
                <input
                  type="text"
                  className="w-full h-[52px] border border-grey rounded-lg  outline-none  f-f-r text-lg text-black pl-[22px]"
                  placeholder="Email"
                />
              </div>
            </div>

            <select
              id="countries"
              className="custom-select  w-full h-[52px] border border-grey rounded-lg   f-f-r text-lg text-black px-[22px] mt-5 outline-none "
          defaultValue="US"
          >
              <option className=" f-f-r text-lg text-black " >
                Reasons
              </option>
              <option className=" f-f-r text-lg text-black " value="US">
                United States
              </option>
              <option className=" f-f-r text-lg text-black " value="CA">
                Canada
              </option>
              <option className=" f-f-r text-lg text-black " value="FR">
                France
              </option>
              <option className=" f-f-r text-lg text-black " value="DE">
                Germany
              </option>
            </select>

            <textarea
              name=""
              id=""
              className=" w-full h-auto md:h-[224px] border border-grey rounded-lg  outline-none  f-f-r text-lg text-black p-[22px] mt-5  "
              placeholder="Share your Comments"
            ></textarea>

            <button className="w-[116px] h-[41px] bg-green hover:bg-black-light hover:text-white transition-colors duration-500 rounded-[20px] f-f-sm text-sm text-black-light mt-5">
              Send Message
            </button>

            <h2 className=" pb-4 f-f-r text-black-light text-sm mt-2 ">
              {" "}
              Message sent successfull{" "}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
