import React from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";

export default function index() {
  return (
    <div>
      <Navbar />
      <Footer />
    </div>
  );
}
