import React, { useState, useEffect } from "react";
import Navbarlogo from "../../Assets/elvotaimages/Evolta-Logo.svg";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [navbarBg, setNavbarBg] = useState(false);
  const [navbarcollapse, setNavbarcollapse] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarBg(true);
      } else {
        setNavbarBg(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <nav
        className={`${
          navbarBg ? "bg-green" : "bg-white"
        } transition-colors duration-500 border-gray-200 fixed w-full z-20 top-0 start-0`}
      >
        <div className="w-11/12 2xl:w-[1210px] flex flex-wrap items-center justify-between mx-auto py-[13px]">
          <Link
            to="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img
              src={Navbarlogo}
              className="w-[192px] h-[32px]"
              alt="Flowbite Logo"
            />
          </Link>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={() => setNavbarcollapse(!navbarcollapse)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill={`${navbarBg ? "#A1FF75" : "#fff"}`}
              viewBox="0 0 17 14"
            >
              <path
                stroke={`#114036`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={` ${
              navbarcollapse ? "block" : "hidden"
            } w-full md:block md:w-auto`}
            id="navbar-default"
          >
            <ul className="md:items-center flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-[30px] rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent">
              <li>
                <Link
                  to="/solution"
                  className={` ${
                    navbarBg ? " hover:text-white " : " hover:text-green "
                  } block py-2 px-3 rounded md:bg-transparent text-black f-f-sm text-lg md:p-0  transition-colors duration-500 `}
                  aria-current="page"
                >
                  Solution
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  className={` ${
                    navbarBg ? " hover:text-white " : " hover:text-green "
                  } block py-2 px-3 md:text-black f-f-sm text-lg rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 transition-colors duration-500 `}
                >
                  About Us
                </Link>
              </li>
              <li>
                <a
                  href="#contactus"
                  className={` ${
                    navbarBg
                      ? " bg-green md:bg-white  md:hover:bg-black-light md:hover:text-white  "
                      : " bg-green  md:hover:bg-black-light md:hover:text-white  "
                  } block text-center rounded-[26px] py-3 px-[18px]  md:text-black f-f-sm text-lg  md:border-0 transition-colors duration-500 `}
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
