import React from "react";
import Battery from "../../Assets/elvotaimages/Group 19.svg";
import Evolta from "../../Assets/elvotaimages/Evolta.svg";
import Electricgrid from "../../Assets/elvotaimages/Electricgrid.svg";

export default function Bess() {
  return (
    <div className="  w-11/12 2xl:w-[1150px] mx-auto pt-[60px] md:pt-[113px] pb-[80px] md:pb-[124px] ">
      <div className="grid grid-cols-12 sm:gap-10 lg:gap-[60px] xl:gap-20 ">
        <div className="col-span-12 md:col-span-6 xl:col-span-4  relative ">
          <img
            src={Battery}
            className="w-auto md:w-[200px] h-auto md:h-[200px] mx-auto"
            alt=""
          />
          <div className="text-center">
            <h1 className="f-f-b-ex text-2xl text-balck mt-5">BESS</h1>
            <p className="f-f-sm text-base text-black">
              ( Battery Energy Storage System )
            </p>

            <p className="f-f-r text-base text-black mt-3">
              We store energy efficiently through BESS, ensuring availability
              during peak demand times and stabilizing the grid.
            </p>
          </div>
          <div className=" absolute right-[-105px] top-[25%] hidden xl:block ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="135"
              height="2"
              viewBox="0 0 135 2"
              fill="none"
            >
              <path
                d="M1 1L134 0.999988"
                stroke="#114036"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>

        <div className="col-span-12 md:col-span-6 xl:col-span-4 relative ">
          <img
            src={Evolta}
            className="w-auto md:w-[200px] h-auto md:h-[200px] mx-auto mt-10 sm:mt-0"
            alt=""
          />
          <div className="text-center">
            <h1 className="f-f-b-ex text-2xl text-balck mt-5">EVOLTA</h1>
            <p className="f-f-sm text-base text-black">Technologies</p>

            <p className="f-f-r text-base text-black mt-3">
              Our AI-powered algorithms and deep learning models ensure optimal
              charging and discharging times for BESS, managing market risks and
              maximising profitability in electricity trading operations
            </p>
          </div>
          {/* <div className=' absolute right-[-105px] top-[25%] hidden xl:block ' >
            <svg xmlns="http://www.w3.org/2000/svg" width="135" height="2" viewBox="0 0 135 2" fill="none">
<path d="M1 1L134 0.999988" stroke="#114036" strokeWidth="2" strokeLinecap="round"/>
</svg>
            </div> */}
        </div>

        <div className="col-span-12 md:col-span-6 xl:col-span-4  relative ">
          <img
            src={Electricgrid}
            className="w-auto md:w-[200px] h-auto md:h-[200px] mx-auto mt-10 sm:mt-0"
            alt=""
          />
          <div className="text-center">
            <h1 className="f-f-b-ex text-2xl text-balck mt-5">
              Electricity Grid
            </h1>
            <p className="f-f-sm text-base text-black">Stations</p>

            <p className="f-f-r text-base text-black mt-3">
              We seamlessly integrate with the electricity grid, ensuring smooth
              operations and enhanced energy management for a sustainable future
            </p>
          </div>
          <div className=" absolute left-[-105px] top-[25%] hidden xl:block ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="135"
              height="2"
              viewBox="0 0 135 2"
              fill="none"
            >
              <path
                d="M1 1L134 0.999988"
                stroke="#114036"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
